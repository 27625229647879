import { useEffect } from 'react';

import type { S3Schema } from '../../@types/types';
import type { AppError, UIFormValues } from '../../@types/types';

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): UIFormValues; (): any };
type SetFormErrorsFn = (errors: AppError[]) => void;

export const useEocArdonaghSpecialtyEffects = (
  schema: S3Schema,
  formValues: UIFormValues,
  formErrors: AppError[],
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn,
  setFormErrors: SetFormErrorsFn
): void => {
  useEffect(() => {
    let clonedFormErrors = [...(formErrors ?? [])];
    if (formValues.client_approval_letter === 'No') {
      clonedFormErrors.push({
        keyword: 'isWithDisclaimerWarning',
        dataPath: 'client_approval_letter',
        path: 'client_approval_letter',
        message: 'WARNING! Get the Client Disclaimer Letter signed and escalate this before you proceed further!',
      });
    } else {
      clonedFormErrors = [...(formErrors ?? []).filter((e) => e.keyword !== 'isWithDisclaimerWarning')];
    }
    setFormErrors(clonedFormErrors);
  }, [formValues.client_approval_letter]);
};
